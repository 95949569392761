<template>
    <div class="container-inner">
        <div>
            <div class="op-row border-b">
                <div class="col-sm-5">
                    <div class="row">
                        <div class="col-4 v-center pl-0">
                            <div class="lbl">{{ $t('sharedOrderDetail.table') }}#</div>
                        </div>
                        <div class="col-8">
                            <div class="val">{{ shared_tname }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .op-row -->
            <div class="op-row border-b">
                <div class="col-sm-5">
                    <div class="row">
                        <div class="col-4 v-center pl-0">
                            <div class="lbl">{{ $t('sharedOrderDetail.guest') }}#</div>
                        </div>

                        <div class="col-4">
                            <div class="val">
                                {{
                                    uname.indexOf('@') > -1
                                        ? uname.split('@')[0]
                                        : uname.toLowerCase().replace('guest', '')
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .op-row -->
            <div class="op-row">
                <div class="col-sm-5">
                    <div class="row">
                        <div class="col-4 v-center pl-0">
                            <div class="lbl">{{ $t('sharedOrderDetail.people_join') }}</div>
                        </div>

                        <div class="col-4">
                            <div class="val">{{ user_count }}</div>
                            <svg class="rt" width="24" height="24" viewBox="0 0 24 24" @click="do_showparty">
                                <path
                                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .op-row -->

            <!-- party -->
            <div v-show="showparty" class="menus">
                <div v-for="(order, key) in user_foods" :key="key">
                    <div class="menu">
                        <div class="user-status">
                            <div class="pt-1 color-yellow" v-html="getUserNames(key.split('@')[0])"></div>
                            <svg
                                class="pp"
                                :style="getColorByName(key.split('@')[0])"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"
                                />
                            </svg>
                        </div>
                        <div v-if="order.length > 0">
                            <div class="row pt-2 pb-2" v-for="(item, key) in order" :key="key">
                                <div class="user_row_food" style="display: flex; width: 100%">
                                    <div class="col-6">{{ translate('item', item.fname) }}</div>
                                    <div class="col-2 text-center">x {{ item.count }}</div>
                                    <div class="col-4 text-right" style="padding-right: 5px">
                                        ${{ getTotalPrice(item) }}
                                    </div>
                                </div>
                                <div
                                    v-if="item.ofeature_name !== ''"
                                    class="user_row_addon"
                                    style="width: 100%; display: flex"
                                >
                                    <div class="col-6 addon_name">{{ translate('feature_value', item.ofeature_name) }}</div>
                                    <div class="col-2">x 1</div>
                                    <div class="col-4 text-right">&nbsp;</div>
                                </div>
                                <div v-if="item.addon_names !== ''" style="width: 100%">
                                    <div
                                        v-for="(addon, index) in item.addon_names.split('|')"
                                        :key="index"
                                        style="width: 100%"
                                    >
                                        <div
                                            v-for="(addon_item, index_item) in addon.split('@@')"
                                            :key="index_item + '_item'"
                                            class="row_addon"
                                            style="width: 100%; display: flex"
                                        >
                                            <!-- <div class="col-6 addon_name">{{ addon_item }}</div> -->
                                            <div class="col-6 addon_name">{{ translate('addon_value', addon_item) }}</div>
                                            <div class="col-2">
                                                x {{ item.addon_counts.split('|')[index].split('@@')[index_item] }}
                                            </div>
                                            <div class="col-4 text-right">
                                                ${{ getAddonPrice(item, index, index_item) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center text-muted">
                            {{ $t('sharedOrderDetail.nothing') }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="!showparty">
                <div class="orders" v-if="food_list.length > 0 && oid">
                    <div class="accordion" v-for="(food_item, index) in food_list" :key="index">
                        <div class="card">
                            <button class="btn btn-link" @click="toggleDetailCard(index, $event)" type="button">
                                {{ getOrderTitle(food_item.name) }}
                                <span v-if="food_item.voided" style="font-size:12px;color:red;">
                                    (Voided by Store)
                                </span>
                            </button>
                            <div class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    <div
                                        class="row"
                                        v-for="item in food_item.foods"
                                        v-bind:key="item.oid"
                                        @click="do_show_detail(item)"
                                    >
                                        <div class="row_food" style="width: 100%; display: flex">
                                            <div class="col-2 px-0">
                                                <div
                                                    class="user"
                                                    :class="{ confirmed: food_item.status === 'Confirmed' }"
                                                    v-html="getUserName(item.ouser_name.split('@')[0])"
                                                ></div>
                                            </div>
                                            <div class="col-6 text-primary">
                                                <a class="border-bottom d-block text-truncate food_name">
                                                    {{ translate('item', item.fname) }}
                                                </a>
                                            </div>
                                            <div class="col-2">x{{ item.count }}</div>
                                            <div class="col-2 text-right pl-0">${{ getTotalPrice(item) }}</div>
                                        </div>
                                        <div
                                            v-if="item.ofeature_name !== ''"
                                            class="row_addon"
                                            style="width: 100%; display: flex"
                                        >
                                            <div class="col-2 px-0"></div>
                                            <div class="col-6 text-primary">
                                                <a class="border-bottom d-block text-truncate addon_name">
                                                    {{ translate('feature_value', item.ofeature_name) }}
                                                </a>
                                            </div>
                                            <div class="col-2">x1</div>
                                            <div class="col-2 text-right pl-0">&nbsp;</div>
                                        </div>
                                        <div v-if="item.addon_names !== ''" style="width: 100%">
                                            <div
                                                v-for="(addon, index) in item.addon_names.split('|')"
                                                :key="index"
                                                style="width: 100%"
                                            >
                                                <div
                                                    v-for="(addon_item, index_item) in addon.split('@@')"
                                                    :key="index_item + '_item'"
                                                    class="row_addon"
                                                    style="width: 100%; display: flex"
                                                >
                                                    <div class="col-2 px-0"></div>
                                                    <div class="col-6 text-primary">
                                                        <a class="border-bottom d-block text-truncate addon_name">
                                                            {{ translate('addon_value', addon_item) }}
                                                        </a>
                                                    </div>
                                                    <div class="col-2">
                                                        x{{
                                                            item.addon_counts.split('|')[index].split('@@')[index_item]
                                                        }}
                                                    </div>
                                                    <div class="col-2 text-right pl-0">
                                                        ${{ getAddonPrice(item, index, index_item) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="loading_order py-5 text-center">
                    <div v-if="this.oid">
                        <div class="spinner-border text-warning"></div>
                        <div class="mt-3">{{ $t('common.loading') }}</div>
                    </div>
                </div>
            </div>

            <div class="single_button_fixed" @click="go_to_menu">
                {{ $t('sharedOrderDetail.add_more') }}
            </div>
        </div>
        <ReviewLayer />
    </div>
</template>
<style lang="scss" scoped>
.user_row_addon {
    margin-top: 8px;
    .addon_name {
        font-size: 13px;
        padding-left: 28px;
    }
}
.row_food {
    .food_name {
        font-size: 15px;
        line-height: 17px;
    }
}
.row_addon {
    margin: 8px 0 0;
    .addon_name {
        margin-left: 10px;
        line-height: 13px;
    }
}
.rt {
    position: absolute;
    right: -105px;
    bottom: -20px;
}
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.add-more {
    text-align: center;
    a:hover {
        color: var(--themeColorFont);
    }
}

.loading_order {
    font-family: $boldFont;
}
</style>
<script>
import Vue from 'vue';
import '../assets/style/orderdetail.css';
import { mapState, mapActions, mapMutations, mapGetters, NamespacedMappers, createNamespacedHelpers } from 'vuex';
import FoodDetailLayerCart from '../components/FoodDetailLayerCart';
import ReviewLayer from '../components/ReviewLayer.vue';
import moment from 'moment';

import randomColor from 'randomcolor';

let HAS_TOAST = false;

const Orders = createNamespacedHelpers('sharedorderdetail');
const App = createNamespacedHelpers('app');

export default {
    name: 'orderdetail',
    components: {
        // FoodDetailLayerCart
        ReviewLayer
    },
    data() {
        return {
            stel: '(718)888-9068',
            oid: this.$route.query.oid || this.$store.state.app.shared_oid || '',
            containr_height: document.body.clientHeight - 100,
            timer: -1,
            show_detail: false,
            detail_data: null,
            showparty: false
        };
    },
    updated() {
        if (this.msg && !HAS_TOAST) {
            HAS_TOAST = true;
            setTimeout(() => {
                this.set_msg('');
            }, 5000);
        }
    },
    created() {
        this.set_app_title(_title);
        let _title = this.$t('sharedOrderDetail.place_orders');

        if (this.oid) {
            this.initData({
                oid: this.oid.split('-')[0],
                cartid: this.$store.state.app.shared_cart_id,
                router: this.$router
            });
        } else {
            this.set_msg({ msg: this.$t('sharedOrderDetail.no_order') });
        }

        this.set_read_new_order();
    },
    beforeMount() {
        this.timer = setInterval(() => {
            if (this.oid) {
                this.initData({
                    oid: this.oid.split('-')[0],
                    cartid: this.$store.state.app.shared_cart_id,
                    router: this.$router
                });
            }
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    computed: {
        goPayTxt() {
            if (this.store_data.checkout_method === 'self') {
                return this.$t('sharedOrderDetail.goto_pay');
            } else if (this.store_data.checkout_method === 'pos') {
                return this.$t('sharedOrderDetail.request_bill')
            }
            return '';
        },
        hideGoPayButton() {
            return this.store_data.checkout_method === 'no';
        },
        ...mapGetters({
            translate: 'app/translate',
            msg: 'sharedorderdetail/get_shareddetail_msg',
            language: 'app/get_language',
            uname: 'app/get_uname',
            cart_price: 'app/get_cart_price',
            cart_price_vip: 'app/get_cart_price_vip',
            shared_tname: 'app/get_shared_tname'
        }),
        ...mapState({
            user_foods: (state) => state.sharedorderdetail.user_foods
        }),
        ...Orders.mapState({
            food_list: 'foods',
            total_price: 'total_price',
            total_price_vip: 'total_price_vip',
            is_vip: 'is_vip',
            user_count: 'user_count',
            no_data: 'no_data'
        }),
        ...App.mapState(['shared_oid', 'isLoading', 'store_data', 'billCreated'])
    },
    watch: {},
    methods: {
        do_showparty() {
            this.showparty = !this.showparty;
        },
        go_to_party(e) {
            this.$router.push({ path: '/sub/party?oid=' + this.shared_oid });
        },
        ...mapActions({
            initData: 'sharedorderdetail/initData',
            goBill: 'sharedorderdetail/goBill',
            set_msg: 'app/setToast',
            checkout: 'app/checkout',
            waitForBill: 'app/waitForBill',
            ifBillCreated: 'app/ifBillCreated'
        }),
        ...mapMutations({
            set_read_new_order: 'app/APP_HAVE_READ_NEW_ORDER',
            set_app_title: 'app/APP_SET_APP_TITLE'
        }),
        getColorByName(name) {
            const color = randomColor({ seed: name });
            return `fill:${color}`;
        },
        getOrderTitle(title) {
            if (this.language === 'eng') {
                return title;
            } else {
                if (title.startsWith('Original')) {
                    return this.$t('sharedOrderDetail.first_order');
                } else {
                    return this.$t('sharedOrderDetail.more_order')+' - ' + (/\d+/.test(title) ? title.match(/\d+/)[0] : '');
                }
            }
        },
        go_to_menu(e) {
            this.$router.push({ path: '/menu' });
        },
        go_to_bill(e) {
            this.goBill({
                tid: this.$store.state.app.shared_tid,
                oid: this.oid,
                type: this.$store.state.app.type
            });
        },
        do_show_detail(_data, e) {
            this.show_detail = true;
            this.detail_data = _data;
            _data.feature_default_value = _data.ofeature_name;
        },
        close_layer(e) {
            this.show_detail = false;
            this.detail_data = null;
        },
        toggleDetailCard(id, e) {
            const title = e.currentTarget;
            const card = title.nextSibling;
            if (title.className.includes('collapsed')) {
                title.className = title.className.replace('collapsed', '');
                card.className = card.className + ' ' + 'show';
                return;
            }
            title.className = title.className + ' ' + 'collapsed';
            card.className = card.className.replace('show', '');
        },
        getUserNames(name) {
            if(name==='undefined') return '';
            
            let uname = name.toLowerCase().replace('guest', '#');
            if (this.uname && uname === this.uname.replace('Guest', '#')) {
                uname = `<span class="you">(${this.$t('party.you')})</span>`;
            }

            return uname;
        },
        getUserName(name) {
            let uname = name.toLowerCase().replace('guest', '#');
            if (uname === this.uname.replace('Guest', '#')) {
                uname = `<span class="you">(${this.$t('party.you')})</span>`;
            }
            return uname;
        },
        getAddonPrice(food, addonIndex, addonSubIndex) {
            const counts = food.addon_counts.split('|')[addonIndex].split('@@')[addonSubIndex];
            const prices = food.addon_prices.split('|')[addonIndex].split('@@')[addonSubIndex];
            return ((parseFloat(counts) * parseFloat(prices)) || 0).toFixed(2);
        },
        getTotalPrice(item) {
            const itemPrice = this.is_vip == 1 ? item.fprice_vip : item.fprice;
            const itemSum = itemPrice;
            let extra_price = 0;
            if (item.addon_prices) {
                const addon_prices = item.addon_prices.split('@@');
                const addon_counts = item.addon_counts.split('@@');

                for (let i = 0, len = addon_prices.length; i < len; i++) {
                    const items = addon_prices[i].split('|');
                    const prices = addon_counts[i].split('|');
                    for (let j = 0; j < items.length; j++) {
                        extra_price += Number(items[j]) * Number(prices[j]);
                    }
                }
            }
            return ((Number(itemSum) + extra_price) * item.count).toFixed(2);
        }
    }
};
</script>
